// extracted by mini-css-extract-plugin
export var aEmpty = "Mb";
export var aTitle = "Nb";
export var pExpressList = "Ob";
export var aDetail = "Pb";
export var aDate = "Qb";
export var aButton1 = "Rb";
export var aButton2 = "Sb";
export var aButton3 = "Tb";
export var aButton4 = "Ub";
export var aButton5 = "Vb";
export var aButtonHeaderLogin = "Wb";
export var aButtonHeaderRegister = "Xb";
export var aButtonBig = "Yb";
export var aButtonRegister = "Zb";
export var aButtonLogin = "_b";
export var aButtonContinuation = "ac";
export var icon = "bc";
export var aButtonHeaderSpLogin = "cc";
export var aTagEntry = "dc";
export var aTagPayment = "ec";
export var aTagClose = "fc";
export var aTagAnnounce = "gc";
export var aTagLottery = "hc";
export var aTagFree = "ic";
export var aArrow1 = "jc";
export var aArrow2 = "kc";
export var aText = "lc";
export var aInner = "mc";
export var animationMarquee = "nc";