import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Image } from "@atoms/Image"
import { StaticImage } from "gatsby-plugin-image"
import { Section, SectionHeader, SectionContent, SectionMoreLink, sectionCss } from "@layouts/Section"
// import * as css from "@css/blocks/b-index-hero.module.styl"
import { TransitionLink } from "../atoms/Link"
import Border from "@atoms/Border"
import { ExpressList } from "@projects/ExpressList"

export default function TopExpress() {
    const { express, emptyImg } = useStaticQuery(graphql`
          query {
            express :allWpExpress(
                sort: { fields: date, order: DESC }
                limit: 6
                skip: 0
            ) {
                edges {
                    node {
                        ...Express
                    }
                }
            }
            emptyImg : file(relativePath: { eq: "Common__img__empty-room.png" }) { childImageSharp {
                gatsbyImageData(
                    width: 1440
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    quality: 100
                )
            } }
        }
    `)
    
    //state: entry, payment, close
    console.log(">>>>?????",emptyImg)
    return (
        <Section id="express">
            <SectionHeader
                title="Express"
                text="レポート"
            />
            <SectionContent isBorder={false}>
                <ExpressList data={express.edges} emptyImg={emptyImg}/>
            </SectionContent>
            <SectionMoreLink
                title="More"
                text="もっとみる"
                to="/member/express/"
            />
        </Section>
    )
}
