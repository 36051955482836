import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
import * as css from "@css/projects/p-express-list.module.styl"
// import Tags from "@projects/Tags"
import { Image } from "@atoms/Image"
import { TransitionLink } from "../atoms/Link"

function ExpressList({ data, emptyImg}) {
    //empty
    // data = []
    if (data.length % 2 ){
        data.push({ node: { empty: true } })
    }
    return (
        <div className={css.pExpressList}>
            {data.length === 0 ?
                <div className={css.aEmpty}>
                    <h5 className={css.aTitle}>Empty</h5>
                    <p className={css.aText}>まだ投稿がありません。</p>
                </div>
                :
                <ul>
                    {data.map(({node}, i) => {
                        let { date, title, thumbnail, databaseId, empty} = node
                        console.log(">>>",emptyImg)
                        if ( empty ){
                            return(
                                <li key={i}>
                                    <Image data={emptyImg.childImageSharp} />
                                </li>
                            )
                        } else {
                            return (
                                <li key={i}>
                                    <TransitionLink className={css.aInner} to={`/member/express/${databaseId}/`}>
                                        { thumbnail ?
                                            <Image data={thumbnail.node.localFile.childImageSharp} />
                                            :
                                            <Image data={emptyImg.childImageSharp} />
                                        }
                                        <div className={css.aDetail}>
                                            <p className={css.aDate}><span>{date}</span></p>
                                            <p className={css.aTitle}>{title}</p>
                                        </div>
                                    </TransitionLink>
                                </li>
                            )
                        }
                    })}
                </ul>
            }
        </div>
    )
}

export { ExpressList }