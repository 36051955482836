import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Image } from "@atoms/Image"
import { StaticImage } from "gatsby-plugin-image"
import { Section, SectionHeader, SectionContent, SectionMoreLink, sectionCss } from "@layouts/Section"
import * as css from "@css/blocks/b-index-hero.module.styl"
import { TransitionLink } from "../atoms/Link"
import Border from "@atoms/Border"
import { MeetList } from "@projects/MeetList"

export default function IndexAbout() {
    const { meet } = useStaticQuery(graphql`
          query {
            meet :allWpMeet(
                sort: { fields: date, order: DESC }
                limit: 3
            ) {
                edges {
                    node {
                        ...Meet
                    }
                }
            }
        }
    `)
    return (
        <Section id="meet">
            <SectionHeader
                title="Meet"
                text="チケット情報"
            />
            <SectionContent>
                <MeetList data={meet.edges} />
            </SectionContent>
            <SectionMoreLink
                title="More"
                text="もっとみる"
                to="/member/meet/"
            />
        </Section>
    )
}
