import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
// import { Image } from "@atoms/Image"
// import { StaticImage } from "gatsby-plugin-image"
// import { Section, SectionHeader, SectionContent, SectionMoreLink, sectionCss} from "@layouts/Section"
// import * as css from "@css/blocks/b-index-about.module.styl"
// import { TransitionLink } from "../atoms/Link"
// import Border from "@atoms/Border"
import Seo from "@atoms/Seo"
import Hero from "@blocks/IndexHero"
import TopMarquee from "@blocks/TopMarquee"
import TopBanner from "@blocks/TopBanner"
import TopNews from "@blocks/TopNews"
import TopMeet from "@blocks/TopMeet"
import TopRoom from "@blocks/TopRoom"
import TopYuToYou from "@blocks/TopYoToYou"
import TopExpress from "@blocks/TopExpress"
import TopContinuation from "@blocks/TopContinuation"
import TopYULetter from "@blocks/TopYULetter"

export default function TopBlock()
{
    return(
        <main>
            <Hero />
            <TopMarquee />
            <TopBanner />
            <TopNews />
            <TopMeet />
            <TopRoom />
            <TopYuToYou />
            <TopExpress />
            <TopYULetter />
            <TopContinuation/>
        </main>
    )
}
