import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Image } from "@atoms/Image"
import { StaticImage } from "gatsby-plugin-image"
import { Section, SectionHeader, SectionContent, SectionMoreLink, sectionCss } from "@layouts/Section"
import * as css from "@css/blocks/b-index-hero.module.styl"
import { TransitionLink } from "../atoms/Link"
import Border from "@atoms/Border"
import { RoomList } from "@projects/RoomList"

export default function TopRoom() {
    const { room } = useStaticQuery(graphql`
          query {
            room :allWpRoom(
                sort: { fields: date, order: DESC }
                limit: 5
                skip: 0
            ) {
                edges {
                    node {
                        ...Room
                    }
                }
            }
        }
    `)
    //state: entry, payment, close
    console.log(room)
    return (
        <Section id="room">
            <SectionHeader
                title="Room"
                text="オフショット"
            />
            <SectionContent isBorder={false}>
                <RoomList data={room.edges} isPickup={true}/>
            </SectionContent>
            <SectionMoreLink
                title="More"
                text="もっとみる"
                to="/member/room/"
            />
        </Section>
    )
}
