import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Image } from "@atoms/Image"
import { StaticImage } from "gatsby-plugin-image"
import { Section, SectionHeader, SectionContent, SectionMoreLink, sectionCss } from "@layouts/Section"
// import * as css from "@css/blocks/b-index-hero.module.styl"
import { TransitionLink } from "../atoms/Link"
import Border from "@atoms/Border"
import { ExpressList } from "@projects/ExpressList"
import * as css from "@css/projects/p-continuation.module.styl"

export default function TopContinuation() {
    
    return (
        <section className={css.pContinuation} id="continuation">
            <div className={css.pContinuation__inner}>
                <div className={css.aButtons}>
                    <TransitionLink to="/keizoku" className={css.aButtonContinuation}>会員継続案内</TransitionLink>
                </div>
            </div>
        </section>
    )
}
