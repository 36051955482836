import React from "react"
import * as css from "@css/projects/p-meet-list.module.styl"
// import Tags from "@projects/Tags"
import { Image } from "@atoms/Image"
import { TransitionLink } from "../atoms/Link"

function MeetList({ data }) {
    console.log(data)
    // data = []
    return (
        <div className={css.pMeetList}>
            { data.length === 0 ?
                <div className={css.aEmpty}>
                    <h5 className={css.aTitle}>Empty</h5>
                    <p className={css.aText}>まだ投稿がありません。</p>
                </div>
                :
                <ul>
                    {data.map(({node}, i) => {
                        let { title, content, date, uri, acf_meet, databaseId } = node
                        let { status, info } = acf_meet
                        let statusVal = status.split(':')[0]
                        return (
                            <li key={i}>
                                <TransitionLink className={css.aInner} to={`/member/meet/${databaseId}/`}>
                                    <h5 className={css.aTitle}>
                                        {title}
                                        <span className={css.aArrow2}></span>
                                    </h5>
                                    <div className={css.aContent}>
                                        <div className={css.aRow}>
                                            <p className={css.aLabel}>会場・日程</p>
                                            <div className={css.aText} dangerouslySetInnerHTML={{__html: info}}/>
                                        </div>
                                        <div className={css.aRowFlex}>
                                            <p className={css.aLabel}>受付状況</p>
                                            {statusVal === 'entry' &&
                                                <span className={css.aTagEntry}>申込受付中</span>
                                            }
                                            {statusVal === 'payment' &&
                                                <span className={css.aTagPayment}>入金期間中</span>
                                            }
                                            {statusVal === 'end' &&
                                                <span className={css.aTagClose}>受付終了</span>
                                            }
                                            {statusVal === 'announce' &&
                                                <span className={css.aTagAnnounce}>申込受付開始前</span>
                                            }
                                            {statusVal === 'lottery' &&
                                                <span className={css.aTagLottery}>抽選中</span>
                                            }
                                            {statusVal === 'free' &&
                                                <span className={css.aTagFree}>無料配信イベント</span>
                                            }
                                        </div>
                                    </div>
                                </TransitionLink>
                            </li>
                        )
                    })}
                </ul>
            }
        </div>
    )
}

export { MeetList }