import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Image } from "@atoms/Image"
import { StaticImage } from "gatsby-plugin-image"
import { Section, SectionHeader, SectionContent, SectionMoreLink, sectionCss } from "@layouts/Section"
import * as css from "@css/blocks/b-index-hero.module.styl"
import { TransitionLink } from "../atoms/Link"
import Border from "@atoms/Border"
import { YuToYouList } from "@projects/YuToYouList"

export default function TopYuToYou() {
    const { yutoyou, empty } = useStaticQuery(graphql`
          query {
            yutoyou :allWpYuToYou(
                sort: { fields: date, order: DESC }
                limit: 5
                skip: 0
            ) {
                edges {
                    node {
                        ...YuToYou
                    }
                }
            }
            empty : file(relativePath: { eq: "Common__img__empty-yutoyou.png" }) { childImageSharp {
                ...Thumbnail
            } }
        }
    `)
    console.log(">>>>",empty)
    //state: entry, payment, close
    return (
        <Section id="yutoyou">
            <SectionHeader
                title="YUtoYOU"
                text="動画配信＆生配信"
            />
            <SectionContent>
                <YuToYouList data={yutoyou.edges} empty={empty}/>
            </SectionContent>
            <SectionMoreLink
                title="More"
                text="もっとみる"
                to="/member/yu-to-you/"
            />
        </Section>
    )
}
