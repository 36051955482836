import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Image } from "@atoms/Image"
// import { StaticImage } from "gatsby-plugin-image"
// import { Section, SectionHeader, SectionContent, SectionMoreLink, sectionCss} from "@layouts/Section"
import * as css from "@css/blocks/b-top-banner.module.styl"
import { TransitionLink } from "@atoms/Link"
// import Border from "@atoms/Border"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "@css/vender/slick-theme.css";

export default function TopBanner() {
    const q = useStaticQuery(graphql`
        query {
            empty : file(relativePath: { eq: "Common__img__empty-banner.jpg" }) { childImageSharp {
                ...Thumbnail
            } }
            banners: allWpTopBanners {
                edges {
                    node {
                        acf_topbanners {
                            url
                        }
                        title
                        date(formatString: "YYYY.MM.DD")
                        thumbnail: featuredImage {
                            node {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            width: 424
                                            height: 238
                                            placeholder: BLURRED
                                            formats: [AUTO, WEBP]
                                            quality: 100
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,//max-width用
        centerPadding: 0,//variablewidthと競合
        arrows: false,
        responsive: [
            {
                breakpoint: 424,
                settings: {
                    variableWidth: false
                }
            }
        ]
    }
    let bannersData = [...q.banners.edges]
    if (q.banners.edges.length < 3) {
        let count = 3 - q.banners.edges.length
        for (let i = 0; i < count; i++) {
            bannersData.push({
                node: {
                    title: 'empty',
                    thumbnail: q.empty
                }
            })
        }
    }

    return (
        <>
            {
                q.banners.edges.length === 0 ?
                    null
                    :
                    <section className={css.pTopBanner} id="banner">
                        <div className={css.pTopBanner__inner}>
                            <Slider {...settings}>
                                {
                                    bannersData.map(({ node }, i) => {
                                        let { title, thumbnail, acf_topbanners} = node
                                        if (title !== 'empty') {
                                            return (
                                                <TransitionLink className={css.pTopBanner__item} key={i} to={acf_topbanners.url} blank>
                                                    <div className={css.aInner}>
                                                        <Image data={thumbnail.node.localFile} alt={title} />
                                                    </div>
                                                </TransitionLink>
                                            )
                                        } else {
                                            return (
                                                <div className={css.pTopBanner__itemEmpty} key={i}>
                                                    <div className={css.aInner}>
                                                        <Image data={thumbnail} alt={title} />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </Slider>
                        </div>
                    </section>
            }
        </>
    )
}
