// extracted by mini-css-extract-plugin
export var aButton1 = "ve";
export var aButton2 = "we";
export var aButton3 = "xe";
export var aButton4 = "ye";
export var aButton5 = "ze";
export var aButtonHeaderLogin = "Ae";
export var aButtonHeaderRegister = "Be";
export var aButtonBig = "Ce";
export var aButtonRegister = "De";
export var aButtonLogin = "Ee";
export var aButtonContinuation = "Fe";
export var icon = "Ge";
export var aButtonHeaderSpLogin = "He";
export var pContinuation = "Ie";
export var pContinuation__inner = "Je";
export var aButtons = "Ke";
export var animationMarquee = "Le";