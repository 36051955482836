import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import { Image, ImageParams, ImagesPath} from "@atoms/Image"
// import { StaticImage } from "gatsby-plugin-image"
import Marquee from "react-fast-marquee"
import dayjs from 'dayjs'
import * as css from "@css/blocks/b-top-marquee.module.styl"

export default function TopMarquee()
{
    const q = useStaticQuery(graphql`
          query {
            data : allWpMarquee(sort: { fields: date, order: DESC }){
                edges {
                    node {
                        title
                        date(formatString: "YYYY.MM.DD")
                    }
                }
            }
        }
    `)
    let options = {
        gradientColor: false,
    }

    return(
        <section className={css.bTopMarquee} id="marquee">
            <Marquee {...options}>
                { q.data.edges.map(({node},i)=>{
                    let { date, title} = node
                    if( date ){
                        return(
                            <div className={css.bTopMarquee__item} key={i}>
                                <span className={css.aDate}>{date}</span>
                                <span className={css.aTitle}>{title}</span>
                            </div>
                        )
                    }
                }) }
            </Marquee>
        </section>
    )
}
