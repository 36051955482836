// extracted by mini-css-extract-plugin
export var aEmpty = "hb";
export var aTitle = "ib";
export var aButton1 = "jb";
export var aButton2 = "kb";
export var aButton3 = "lb";
export var aButton4 = "mb";
export var aButton5 = "nb";
export var aButtonHeaderLogin = "ob";
export var aButtonHeaderRegister = "pb";
export var aButtonBig = "qb";
export var aButtonRegister = "rb";
export var aButtonLogin = "sb";
export var aButtonContinuation = "tb";
export var icon = "ub";
export var aButtonHeaderSpLogin = "vb";
export var aTagEntry = "wb";
export var aTagPayment = "xb";
export var aTagClose = "yb";
export var aTagAnnounce = "zb";
export var aTagLottery = "Ab";
export var aTagFree = "Bb";
export var aArrow1 = "Cb";
export var aArrow2 = "Db";
export var aText = "Eb";
export var pMeetList = "Fb";
export var aContent = "Gb";
export var aRow = "Hb";
export var aRowFlex = "Ib";
export var aLabel = "Jb";
export var aInner = "Kb";
export var animationMarquee = "Lb";