import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Image } from "@atoms/Image"
// import { StaticImage } from "gatsby-plugin-image"
import { Section, SectionHeader, SectionContent, SectionMoreLink, sectionCss} from "@layouts/Section"
import * as css from "@css/blocks/b-index-letter.module.styl"
import { TransitionLink } from "../atoms/Link"
// import Border from "@atoms/Border"
// import { loginHandler, registerHandler } from "@utility/UrlData"

export default function TopYULetter()
{
    const q = useStaticQuery(graphql`
        query {
            icon_letter : file(relativePath: { eq: "Common__icn__letter.svg" }) {
                publicURL
            }
        }
    `)
    return(
        <div className={css.bIndexLetter} id="about">
            {/* <div className={css.bIndexAbout__bg} style={{ backgroundImage: `url(${q.bg.publicURL})` }} /> */}
            <div className={css.bIndexLetter__inner}>
                <Section isMargin={false}>
                    <SectionHeader
                        title="YU Letter"
                        text="ファンレター"
                    />
                    <SectionContent>
                        <section>
                            <p className="center">
                            城田優への応援メッセージや、公演・TVの感想など、城田優に伝えたい想いをファンレターにしてお送りください！
                            </p>
                            <p>
                            <TransitionLink className={sectionCss.aButtonBig} to="/member/yu-letter/">
                                <img className={sectionCss.icon} src={q.icon_letter.publicURL}/>ファンレターはこちら
                            </TransitionLink>
                            </p>
                            <p className="center">
                            ※現在、郵送でのファンレター、プレゼントでのお受け取りは行なっておりません。
                            </p>
                        </section>
                    </SectionContent>
                </Section>
            </div>
        </div>
    )
}
