import * as React from "react"
import { graphql, navigate} from "gatsby"
// import { Image } from "@components/atoms/Image"
// import { StaticImage } from "gatsby-plugin-image"
import TopBlcok from "@blocks/Top"
// import IndexBlcok from "@blocks/Index"
import Seo from "@components/atoms/Seo"

// import { RecoilRoot, useRecoilState } from 'recoil'
// import { userLoginState } from "@status/UserState"
// import TopMarquee from "@blocks/TopMarquee"
import { Router } from "@reach/router"
import PrivateRoute from "@projects/PrivateRoute"

function MemberIndexPage({data})
{
    // const [userLogin] = useRecoilState(userLoginState)
    // const [login, setLogin] = React.useState(false)
    // React.useEffect(()=>{
    //     setLogin(userLogin)
    // },[userLogin])

    return(
        <>
            <Seo/>
            <Router basepath="/member">
                <PrivateRoute path="/" component={TopBlcok}/>
            </Router>  
        </>
    )
}

export const pagerQuery = graphql`
    fragment Background on ImageSharp {
        gatsbyImageData(
            width: 1440
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
        )
    }
    fragment Thumbnail on ImageSharp {
        gatsbyImageData(
            width: 1440
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
        )
    }
    fragment BigCard on ImageSharp {
        gatsbyImageData(
            layout: FULL_WIDTH
            width: 515
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
        )
    }
    fragment Card on ImageSharp {
        gatsbyImageData(
            layout: FULL_WIDTH
            width: 230
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
        )
    }
`
export default MemberIndexPage
